<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <label>Логин</label>
    <input type="text" v-model="login" placeholder="Введите логин" class="form-control" /><br>
    <label>Пароль</label>
    <input type="password" v-model="password" placeholder="Введите пароль" class="form-control" /><br>
    <label>Возраст</label>
    <input type="number" v-model="age" min="18" placeholder="Введите возраст" class="form-control" /><br>
    <label>Дата регистрации</label><br>
    <input type="date" v-model="date" placeholder="Введите дату" class="form-control" />
    <br>
    <div>
        <h3>Введенная информация</h3>
        <p>Логин: {{login}}</p>
        <p>Пароль: {{password}}</p>
        <p>Возраст: {{age}}</p>
        <p>Дата регистрации: {{date}}</p> 
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return { 
        login:'',
        password:'',
        date: '',
        age: 22
    }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
